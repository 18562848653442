<template>
  <div>
    <!-- Start Header Area -->
    <HeaderMultiPageRoute>
      <img slot="logo" src="../assets/img/logo/rikorisu-long-nologo.png" />
    </HeaderMultiPageRoute>
    <!-- End Header Area -->

    <!-- Start 404 Page  -->
    <div class="error-page-inner bg_color--3">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner">
              <h1 class="heading-title theme-gradient">404!</h1>
              <h3 class="sub-title">迷路了……</h3>
              <span>未能寻到您想要前往的方向</span>
              <div class="error-button">
                <router-link to="/" class="rf-btn"
                  >退回主页</router-link
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End 404 Page  -->

    <Legal />
  </div>
</template>

<script>
  import HeaderMultiPageRoute from "@/components/header/HeaderMultiPageRoute.vue";
  import Legal from "@/components/rikorisu/Legal.vue";
  export default {
    components: {
      Legal,
      HeaderMultiPageRoute,
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss" scoped></style>
