<template>
  <div class="ms-container-wrapper">
    <div style="display: flex; width: 100vw; justify-content: center; vertical-align: center; align-self: flex-end">
      <div class="legal-wrapper">
      <div class="ms-section ms-table container">
        <div class="ms-tableCell content pt-6 pb-6">
          <img :src="logoWhiteBordered" alt="RIKORISU Brand Logo" style="max-width: 20rem">

          <p><router-link to='/privacy-policy'>隐私协议</router-link></p>
          <br/>
          <p><a href="https://beian.miit.gov.cn/#/Integrated/index">沪ICP备2021026213号-1</a> </p>
          <p>亲爱的市民朋友，上海警方反诈劝阻电话“962110”系专门针对避免您财产被骗受损而设，请您一旦收到来电，立即接听</p>
          <p>©RIKORISU 2023 | 上海可丽可丽商贸有限公司</p>
          <div class="legal-icons">
            <a
                class="legal-icon"
                v-for="(icon, i) in legalIcons"
                :key="i"
                :href="icon.url" rel="nofollow" target="_blank" :style="{ backgroundImage: 'url(' + icon.img + ')'}"></a>
          </div>
        </div>
      </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      logoWhiteBordered: require("../../assets/img/logo/rikorisu-long-nologo.png"),
      legalIcons: [
        {
          "img": require("../../assets/img/legal/gxbbacxsm.png"),
          "url": "https://beian.miit.gov.cn/#/Integrated/index",
          "alt": "工信部域名信息备案管理系统"
        },
        {
          "img": require("../../assets/img/legal/shhlwzx.png"),
          "url": "https://www.shjbzx.cn/",
          "alt": "上海市互联网违法和不良信息举报中心"
        }
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
.legal-wrapper {
  height: 60vh;
  width: 100vw;
  background-color: #333333;

  @media (max-width: 767px){
    height: 100vh;
    padding: 0 30px;
  }
}
.ms-left {
  flex: 0 0 40%
}

p {
  color: #AAAAAA !important;
}

a {
  color: #CCCCCC !important;
}
.legal-icons {
  margin-top: 10vh;
  display: flex;
}
.legal-icons a {
  border-radius: 5px;
  border: solid 1px #FFFFFF;
  padding: 5px;
  width: 174px;
  height: 69px;
  background-size: contain;
  background-position: center center;
  margin: 0 10px;
}
</style>
